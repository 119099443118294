/* certificate.css */

body {
    background-color: #e0f7fa;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: larger;
}

.all {
    padding: 20px;
    text-align: center;
}

.all h1 {
    margin-bottom: 20px;
    color: royalblue;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.space {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.single {
    width: 80%;
    height: auto;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.single:hover {
    transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
    .space {
        grid-template-columns: repeat(2, 1fr);
    }
    /* .single {
        width: 50%;
        max-width: 70%;
    } */
    
}

@media (max-width: 480px) {
    .space {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

}
