/* Base styles */
body {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #e0f7fa; 
    color: #333; 
    line-height: 1.6;
    font-size: larger;
}

/* Container styling */
.total {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px; /* Reduced gap */
    flex-wrap: wrap;
}

/* Left side styling */
.left-side {
    flex: 1;
    width: 60%;
    max-width: 80%;
    background: #ffffff; 
    border-radius: 14px; /* Reduced border-radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Reduced shadow */
    padding: 20px; /* Slightly increased padding for better spacing */
    text-align: center;
    transition: transform 0.3s ease;
    height: 60%; /* Allow height to adjust based on content */
    max-height: 400px; /* Increased max-height */
    overflow: hidden; /* Hide overflow */
    position: relative; /* Needed for positioning the image */
}

.left-side:hover {
    transform: translateY(-5px); 
}

/* Heading and text styling */
.left-side h1 {
    font-size: 1.4rem; /* Adjusted font size */
    color: #00796b; 
    margin-bottom: 10px; /* Adjusted margin */
}

.left-side h2 {
    font-size: 1.2rem; /* Adjusted font size */
    color: #004d40; 
    margin-bottom: 15px; /* Adjusted margin */
}

.left-side h2 span {
    font-weight: bold;
    color: #f06292; 
}

.left-side p {
    font-size: 0.9rem; /* Adjusted font size */
    color: #555;
    margin-top: 0;
}

/* Right side styling */
.right-side {
    /* flex: 1; */
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.right-side img {
    max-width: 100%; /* Ensure image scales proportionally */
    height: auto;
    max-height: 100%; /* Ensure image scales proportionally */
    border-radius: 15%;
    /* border: 4px solid #73b9b1; Border width remains the same */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Reduced shadow */
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
    .left-side {
        max-height: 450px; /* Adjusted max-height */
    }

    .left-side h1 {
        font-size: 1.6rem; /* Adjusted font size */
    }

    .left-side h2 {
        font-size: 1.4rem; /* Adjusted font size */
    }

    .left-side p {
        font-size: 1rem; /* Adjusted font size */
    }

    .right-side img {
        max-height: 50vh; /* Adjusted height */
    }
}

@media (max-width: 767px) {
    .total {
        gap: 20px; 
        flex-direction: column;
        align-items: center; 
    }

    .left-side {
        max-width: 90%;
        padding: 20px; /* Increased padding */
        max-height: 400px; /* Adjusted max-height */
    }

    .right-side img {
        max-height: 50vh; /* Increased height */
    }
}

  


