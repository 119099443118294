
.comp {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;
    margin: 0 auto; 
    max-width: 1200px; 
}


.project {
    width: 45%; 
    padding: 15px;
    display: flex;
    height: 45%;
    flex-direction: column;
    align-items: center; 
}

.project h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.project-image {
    width: 100%; 
    max-width: 400px; 
    height: 40vh;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    object-fit: cover; 
}

.skills-container {
    width: 45%; 
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.skills1 {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
}

.skills1 h2 {
    font-size: 18px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 18px;
    color: #333;
}

.skills1 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.skills1 li {
    margin-bottom: 15px;
}

.skills1 h3 {
    margin: 0;
    font-size: 18px;
    color: #666;
}

.bar {
    display: block;
    height: 15px; 
    background-color: #e0e0e0; 
    border-radius: 12px; 
    overflow: hidden;
    width: 100%;
    position: relative;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}


.html-skill .bar::before {
    width: 80%;
    position: absolute;
    background-color: #3498db;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2); 
}
.IOT-skill .bar::before{
    width: 62%;
    position: absolute;
    background-color: tomato;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.css-skill .bar::before {
    width: 80%; 
    position: absolute;
    background-color: #3498db; 
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.js-skill .bar::before {
    width: 65%; 
    position: absolute;
    background-color: #3498db; 
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.react-skill .bar::before {
    --skill-width: 67%; 
    background-color: #3498db; 
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.bar::before {
    content: '';
    display: block;
    height: 100%;
    width: var(--skill-width);
    background-color: #2ecc71; 
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
}
.fill{
    display: flex;
    width: 55%;
    flex-direction: row;
    object-fit: cover;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .comp {
        flex-direction: column; 
        align-items: center;
    }
    
    .skills-container,
    .project {
        width: 90%; 
        margin-bottom: 20px;
    }
    
    .skills1 h2 {
        font-size: 18px;
    }
    
    .skills1 h3 {
        font-size: 16px;
    }
    
    .bar {
        height: 15px; 
    }
    .fill{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .comp {
        flex-direction: column; 
        align-items: center;
    }

    .skills-container,
    .project {
        width: 90%; 
        margin-bottom: 20px;
    }
    .fill{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


