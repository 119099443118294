
.contact-container {
    display: flex;
    /* flex-wrap: nowrap; */
    padding: 20px;
    gap: 20px;
    justify-content: center;
    background-color: #e0f7fa; 
    border-radius: 10px; 
    font-size: larger;
}

.left-side1 {
    flex: 1;
    min-width: 200px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-img {
    width: 250px; 
    height: 275px; 
    border-radius: 30%; 
    border: 4px solid #151c77; 
    object-fit: cover; 
}

.right-side1 {
    flex: 1;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


.li {
    display: flex;
    align-items: center;
    margin-bottom: 20px; 
    padding: 10px;
    background-color: #ffffff; 
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.address-logo {
    margin-right: 15px;
    font-size: 1.1em; 
    color: #0077b5; 
}


.address-logo-tick {
    margin-left: 10px;
    color: #4CAF50; 
}


a {
    text-decoration: none;
    color: #333; 
    font-weight: bold;
}

p {
    margin: 0;
    font-size: 0.8em; 
}


@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }
    
    .profile-img {
        width: 250px; 
        height: 250px; 
    }

    .address-logo {
        font-size: 1.4em; 
    }
}

@media (max-width: 480px) {
    .address-logo {
        font-size: 1.2em; 
    }
}

