/* Base styles for the navigation bar */
.complete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    padding: 5px;
    font-size: larger;
}

.nav-bar {
    background-color: #3e26c5;
    padding: 20px;
    width: 60%;
    z-index: 1000;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
    font-size: 18px;
}

/* Navigation menu styling */
.nav-link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.nav-link:hover {
    color: #aaa;
}

.nav-text {
    margin-left: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;

}

/* Mobile Menu Styling */
@media (max-width: 768px) {
    .nav-text {
        display: none; /* Hide text on mobile */
    }
    .nav-bar{
        width: 50%;
        height: 5vh;
    }
    .nav-link{
        font-size: 20px;
    }
}




